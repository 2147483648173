import React, {useEffect, useRef, useState} from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import axios from 'axios';
import ReactTimeAgo from "react-time-ago";
// @ts-ignore
import Image from "react-graceful-image";
import {Select, Card, Typography, List, Row, Col, Spin, Modal} from 'antd';

const {Option} = Select;
const {Title} = Typography;
import styles from './WebSocketComponent.module.css';
import moment from "moment";

type Props = {
    locations: string[];
}

const WebSocketComponent: React.FC<Props> = ({locations}) => {
    const [locationCode, setLocationCode] = useState(locations[0] || '');
    const [image, setImage] = useState<any>(null);
    const [text, setText] = useState<string>('');
    const [sessionTime, setSessionTime] = useState<any>(null);
    const [sessions, setSessions] = useState<any>([]);
    const [blink, setBlink] = useState(false);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<any>(false);
    const [selectedSession, setSelectedSession] = useState<any>(null);

    const rwsRef = useRef<ReconnectingWebSocket | null>(null);
    const lastMessageTimeRef = useRef<Date>(new Date());

    const backendPath = 'https://api.yewfi.com'
    // const backendPath = 'http://localhost:5000'

    const statusColors = {
        'PAID': 'green',
        'ERROR': 'red',
        'ENROLLED': 'orange',
        '-': 'black',
        'BAD': 'red'
    }

    useEffect(() => {

        if (rwsRef.current) {
            rwsRef.current.close();
            rwsRef.current = null;
        }

        fetchLatestSessions();

        const rws = new ReconnectingWebSocket(`wss://wiwk4snb0a.execute-api.us-west-2.amazonaws.com/production?location_code=${locationCode}`);
        rwsRef.current = rws;

        rws.addEventListener('message', (message) => {
            const data = JSON.parse(message.data);
            processSession(data)
        });

        // create an interval that checks the last message time and refreshes connection if needed
        const checkInterval = setInterval(() => {
            const now = new Date();
            const diffInMinutes = (now.getTime() - lastMessageTimeRef.current.getTime()) / (1000 * 60);

            if (diffInMinutes >= 9) { // adjust time as needed
                if (rwsRef.current) {
                    rwsRef.current.reconnect(1000, 'Manual reconnect');
                    lastMessageTimeRef.current = new Date();
                }
            }
        }, 1000 * 60); // check every minute

        // return a cleanup function to clear interval and close connection when component unmounts or locationCode changes
        return () => {
            clearInterval(checkInterval);
            if (rwsRef.current) {
                rwsRef.current.close();
            }
        };


    }, [locationCode]);

    useEffect(() => {
        if (image) {
            setBlink(true);
            const timer = setTimeout(() => setBlink(false), 3000); // blink for 2 seconds
            return () => clearTimeout(timer);
        }
    }, [image]);

    // group sessions on component render or update
    useEffect(() => {
    }, [sessions]);

    const fetchLatestSessions = async () => {
        try {
            const response = await axios.get(`${backendPath}/event/${locationCode}/last_face_sessions/20`);
            const data = response.data;
            processSessions(data)
        } catch (err) {
            console.error(err);
        }
    };

    const processSession = async (data: any) => {
        console.log(`Received session: ${JSON.stringify(data, null, 2)}`)
        setImage(data.meta?.session_image);
        setSessionTime(new Date(data.event_ts));
        lastMessageTimeRef.current = new Date(); // update last message time

        setSessions((sessions: any[]) => {
            // Add the new session
            const newSessions = [...sessions, data].sort((a, b) => new Date(b.date_created).getTime() - new Date(a.date_created).getTime()).slice(0, 10);
            return newSessions;
        });
    }

    const processSessions = async (data: any) => {
        const last = data[0]
        setImage(last.meta?.session_image);
        setSessionTime(new Date(last?.date_created));
        lastMessageTimeRef.current = new Date(); // update last message time

        setSessions(data);
    }

    const showSessionInfo = (e: any, session: any) => {
        e.preventDefault();
        setSelectedSession(session);
        setIsDetailsModalOpen(true);
    }

    const handleDetailsModalClose = () => {
        setIsDetailsModalOpen(false);
        setSelectedSession(null);
    }

    const showSession = (session: any) => {
        setImage(session?.meta?.session_image);
        setSessionTime(new Date(session?.date_created))
    }


    return (
        <div style={{height: '100%', maxHeight: '100%', width: '95%', display: 'flex', flexDirection: 'column'}}>
            <Row style={{
                paddingTop: '20px',
                paddingBottom: '10px'
            }}>
                {locations.length > 1 &&
                    <Select
                        defaultValue={locationCode}
                        style={{width: '100%'}}
                        onChange={(value: any) => setLocationCode(value)}
                    >
                        {
                            locations.map((location) =>
                                <Option value={location} key={location}>{location}</Option>
                            )
                        }
                    </Select>
                }
            </Row>
            <div style={{flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%'}}>
                <Row style={{
                    flex: '0 0 calc(50% - (50px/2))',
                    overflow: 'auto',
                    paddingTop: '10px',
                    paddingBottom: '10px'
                }}>
                    <div style={{display: 'grid', placeItems: 'center', width: '100%'}}>
                        {image
                            ? <div className={`${styles.imageContainer} ${blink ? styles.blink : ''}`}
                                   style={{
                                       position: 'relative',
                                       display: 'flex',
                                       width: 'calc(100% - 20px)',
                                       alignItems: 'center',
                                       justifyContent: 'center',
                                       alignContent: 'center'
                                   }}>
                                <Image src={image}
                                       alt='Session Image'
                                       style={{maxWidth: '100%', width: '100%', height: 'auto'}}
                                />
                                <div style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 5,
                                    color: 'red',
                                    fontSize: '32px',
                                    fontWeight: 'bold',
                                    textShadow: '2px 2px 0 #ffffff, -2px -2px 0 #ffffff, 2px -2px 0 #ffffff, -2px 2px 0 #ffffff'
                                }}>
                                    {text}
                                </div>
                            </div>
                            : <div style={{
                                width: 'calc(100% - 20px)',
                                height: 'calc(100% - 20px)',
                                backgroundColor: 'grey',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '10px solid white'
                            }}>
                                <h1 style={{color: 'white', fontWeight: 'bold'}}>CLEAR</h1>
                            </div>
                        }
                    </div>
                </Row>
                <Row style={{
                    flex: '0 0 calc(50% - (50px/2))',
                    overflow: 'auto',
                    paddingTop: '10px',
                    paddingBottom: '20px'
                }}>
                    <Card style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>
                        <List
                            dataSource={Object.values(sessions)}
                            renderItem={(session: any, index: number) => {
                                const status = session?.meta?.payment ? (session?.meta?.payment?.payment_status == 'succeeded' ? 'PAID' : 'ERROR') : '-'
                                const statusFontColor: string = statusColors[status]
                                const numVisits = 'New'
                                const amountPaid = session?.meta?.payment?.amount || 0
                                const faces = session.meta?.faces ?? [];
                                const visitorsStr = faces.length == 0 ? 'Unknown' :
                                    (faces.length == 1 ? `User ${faces[0]?.User?.id}` : `${faces.length} Users`)
                                return (
                                    <List.Item key={index} style={{width: '100%', userSelect: 'none'}}
                                               onClick={() => showSession(session)}
                                               onContextMenu={(e) => showSessionInfo(e, session)}
                                    >
                                        <div style={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 1fr 1fr',
                                            width: '100%',
                                            backgroundColor: `transparent`
                                        }}>
                                            <div style={{textAlign: 'start',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                            }}>
                                                {index === 0
                                                    ?
                                                    <div><ReactTimeAgo date={new Date(session.date_created)}
                                                                       locale={'en-US'}
                                                                       timeStyle={'twitter'}/> ago</div>
                                                    : new Date(session.date_created).toLocaleTimeString()
                                                }
                                            </div>
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                                                <div style={{color: visitorsStr.includes('User') ? 'orange' : 'black'}}>{visitorsStr}</div>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                paddingLeft: '20px',
                                                color: statusFontColor
                                            }}>
                                                <div>{status}</div>
                                                {(status == 'PAID' || status == 'ERROR') && <div>${(amountPaid / 100).toFixed(2)}</div>}
                                            </div>
                                        </div>
                                    </List.Item>
                                );
                            }}
                        />
                    </Card>
                </Row>
            </div>
            <Modal centered={true} title={'Session Details'} open={isDetailsModalOpen} onCancel={handleDetailsModalClose} footer={null} style={{maxWidth: '90vw'}}>
                <pre style={{maxHeight: '80vh', maxWidth: '85vw', overflow: 'auto'}}>
                    {JSON.stringify(selectedSession, null, 2)}</pre>
            </Modal>
        </div>
    );
};

export default WebSocketComponent;
