import React, {useState, useEffect} from 'react';
import './App.css';
import WebSocketComponent from "./WebSocketComponent";
import {useAuth0} from "@auth0/auth0-react";
import jwt from 'jwt-decode';

function App() {
    const {user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, logout} = useAuth0();
    const [locations, setLocations] = useState<any>([]);

    useEffect(() => {
        if (isAuthenticated) {
            getAccessTokenSilently({
                authorizationParams: {
                    audience: `https://api.yewfi.com/locations`,
                    scope: "read:current_user",
                }
            })
                .then((accessToken) => {
                    console.log(`AccessToken: ${accessToken}`)
                    if (!accessToken) {
                        console.error('No access token returned');
                        return;
                    }

                    try {
                        const token: any = jwt(accessToken);
                        console.log(JSON.stringify(token));
                        const result: string[] = token.permissions.map((permission: string) => {
                            const splitPermission: string[] = permission.split(':');
                            return splitPermission[1];
                        });
                        setLocations(result);
                    } catch (e) {
                        console.error('Error decoding the JWT', e);
                    }
                })
                .catch((err) => {
                    console.error('Error getting access token', err);
                    if (err.error === 'consent_required') {
                        logout()
                        // Redirect to the desired URL after a short delay to ensure logout completes
                        setTimeout(() => {
                            window.location.assign(window.location.origin);
                        }, 1000); // Adjust the delay as necessary
                    }
                });
        } else if (!isLoading) {
            loginWithRedirect();
        }
    }, [isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, logout]);

    console.log(user)

    return (
        <div className="App" style={{height: '100vh', width: '100vw', overflow: 'hidden'}}>
            <header className="App-header" style={{height: '100%', maxHeight: '100%'}}>
                {locations?.length > 0 &&
                    <WebSocketComponent locations={locations}/>
                }
            </header>
        </div>
    );
}

export default App;
